<template>
  <div class="home">
    <div class="pc">
      <div class="overview">
        <div class="w1200">
          <div class="top">
            <img class="logo" src="../assets/img/logo-black.svg" alt="">
          </div>
          <div class="content">
            <h2>Android（3.1.1）</h2>
            <div style="margin-top: 30px" class="version-tip">Add: Support the Taproot</div>
            <div class="version-tip">Add: Support the Nested Segwit</div>
            <div class="version-tip">Fix: Fix some bugs collected from community</div>
            <div class="download-wrap">
              <div class="down-item down-load-link app-down-load down-play mt_20">
                <a href="https://play.google.com/store/apps/details?id=com.wallet.nabox" target="_blank"></a>
              </div>
              <div class="down-item down-load-link app-down-load down-apk">
                <a href="https://nabox-apk.oss-cn-hongkong.aliyuncs.com/Nabox_latest.apk" target="_blank"></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile" @click="showList=false">
      <div class="mobile-nav bg-white" :class="{fixed: false}">
        <div class="wrap">
          <img class="logo" src="../assets/img/logo-black.svg" alt="">
        </div>
      </div>
      <div class="overview-mobile bg-white">
        <template>
          <h3>{{ 'Android（3.1.1）' }}</h3>
          <div>
            <div style="margin-top: 30px">Add: Support the Taproot</div>
            <div style="margin-top: 10px">Add: Support the Nested Segwit</div>
            <div style="margin-top: 10px">Fix: Fix some bugs collected from community</div>
          </div>
          <div class="btn-cont mt_20">
            <div v-if="isAndroid || isPc" class="btn mr-1 mb-15 down-play">
              <a href="https://play.google.com/store/apps/details?id=com.wallet.nabox"></a>
            </div>
            <div v-if="isAndroid || isPc" class="btn mr-1 mb-15 down-apk">
              <a href="https://nabox-apk.oss-cn-hongkong.aliyuncs.com/Nabox_latest.apk"/>
            </div>
          </div>
          <img class="phone-bg" src="../assets/img/phone.png" alt="">
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LastVersion",
  data() {
    return {
      isAndroid: window.navigator.appVersion.match(/android/gi),
      isIPhone: window.navigator.appVersion.match(/iphone/gi),
      isPc: !(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)),
    }
  }
}
</script>

<style scoped lang="less">
@import "Home";
</style>